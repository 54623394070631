<template>
  <div class="relative py-12 w-full xl:w-9/12 mx-auto min-container pt-36 lg:pt-52">
    <div v-if="paymentId">
      <div v-if="paymentStatus == 'success'">
        <div class="flex flex-col aic">
          <div class="grow">
            <div class="smaller">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 60 60"
                style="enable-background: new 0 0 60 60"
                xml:space="preserve"
              >
                <path
                  class="check"
                  d="M40.61,23.03L26.67,36.97L13.495,23.788c-1.146-1.147-1.359-2.936-0.504-4.314
                c3.894-6.28,11.169-10.243,19.283-9.348c9.258,1.021,16.694,8.542,17.622,17.81c1.232,12.295-8.683,22.607-20.849,22.042
                c-9.9-0.46-18.128-8.344-18.972-18.218c-0.292-3.416,0.276-6.673,1.51-9.578"
                />
              </svg>
            </div>
          </div>
          <h1 class="text-2xl b text-green-400 grow mt-8">
            {{ $t("Payment Successful") }}
          </h1>
          <router-link
            to="/"
            class="text-gray-400 hover:text-gray-900 tracking-wider uppercase grow fs-14"
          >{{ $t("Continue shopping") }}</router-link>
        </div>
      </div>

      <div v-if="paymentStatus == 'fail'">
        <div class="flex flex-col aic">
          <div class="grow">
            <div class="smallerFailed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  class="failed"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                />
              </svg>
            </div>
          </div>
          <h1 class="text-2xl b text-red-500 grow mt-8">
            {{ $t("Your payment has failed!") }}
          </h1>
          <router-link
            to="/dashboard"
            class="text-gray-400 hover:text-gray-900 tracking-wider uppercase grow fs-14"
          >{{ $t("Continue shopping") }}</router-link>
        </div>
      </div>
    </div>
    <div
      v-if="!paymentId"
      class="flex flex-col aic"
    >
      <div class="grow">
        <div class="smaller">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            style="enable-background: new 0 0 60 60"
            xml:space="preserve"
          >
            <path
              class="check"
              d="M40.61,23.03L26.67,36.97L13.495,23.788c-1.146-1.147-1.359-2.936-0.504-4.314
                c3.894-6.28,11.169-10.243,19.283-9.348c9.258,1.021,16.694,8.542,17.622,17.81c1.232,12.295-8.683,22.607-20.849,22.042
                c-9.9-0.46-18.128-8.344-18.972-18.218c-0.292-3.416,0.276-6.673,1.51-9.578"
            />
          </svg>
        </div>
      </div>
      <h1 class="text-2xl b text-success grow mt-8">
        {{ $t("Payment Successful") }}
      </h1>
      <router-link
        to="/"
        class="text-gray-400 hover:text-gray-900 tracking-wider uppercase grow fs-14"
      >{{ $t("Continue shopping") }}</router-link>
    </div>

    <div class="relative py-16 animate__animated animate__fadeIn w-full">
      <div class="w-full xl:w-8/12 mx-auto area-min px-5 md:px-0 py-6 relative">
        <div class="w-full md:w-10/12 xl:w-full mx-auto">
          <div class="flex flex-col space-y-1 border-t border-b bg-white">
            <div class="bg-gray-50">
              <div class="flex flex-col md:flex-row p-5 jcb aic w-full space-y-2">
                <div class="flex md:flex-col space-x-3 md:space-x-0 md:space-y-1 items-center md:items-start w-full md:w-1/5">
                  <span class="fs-12 uppercase">{{ $t("Order placed") }}</span>
                  <b class="fs-14">{{
                    !order.as_soon_as_possible
                      ? formatDate(order.delivery_moment)
                      : $t("as soon as possible")
                  }}</b>
                </div>
                <div class="flex md:flex-col space-x-3 md:space-x-0 md:space-y-1 items-center md:items-start w-full md:w-1/5">
                  <span class="fs-12 uppercase">{{ $t("Total Amount") }}</span>
                  <b class="fs-14">{{ currency }} {{ order.total_with_discount }}</b>
                </div>
                <div
                  v-if="userOrder && userOrder.name"
                  class="flex md:flex-col space-x-3 md:space-x-0 md:space-y-1 items-center md:items-start w-full md:w-1/5 md:mr-auto"
                >
                  <span class="fs-12 uppercase">{{ $t("Dispatched to") }}</span>
                  <b class="fs-14">{{ userOrder.name }}</b>
                </div>
                <div class="flex space-x-3 items-center w-full md:w-1/5 md:justify-end">
                  <span class="fs-12 uppercase">{{ $t("Order") }}</span>
                  <b class="fs-14">#{{ order.invoice_number }}</b>
                  <!-- <i class="fa fa-chevron-down text-primary"></i> -->
                </div>
              </div>
            </div>
            <div class="flex flex-col divide-y-2 divide-gray-100 divide-dashed">
              <div
                class="flex flex-col md:flex-row items-stretch"
                v-for="(product, id) in productOrder"
                :key="id"
              >
                <img
                  :src="
                    product.product_image
                      ? storageUrl + product.product_image
                      : '/dish.webp'
                  "
                  alt=""
                  class="w-full md:w-44 h-40 md:h-auto object-cover"
                />
                <div class="flex flex-col flex-grow px-6 py-4">
                  <div class="flex aic jcb pb-3 border-b border-gray-100">
                    <h2 class="b text-primary-color">
                      {{ product.product_name }} x {{ product.quantity }}
                    </h2>
                    <span class="text-md">{{ currency }} {{ product.price }}</span>
                  </div>
                  <div
                    class="flex flex-col w-full pt-2 pb-3 space-y-1"
                    v-if="product.items.length"
                  >
                    <div
                      class="flex aic jcb"
                      v-for="(item, i) in product.items"
                      :key="i"
                    >
                      <span class="text-gray-600 text-xs"><b class="mr-2">{{ i + 1 }}.</b>
                        {{ item.product_name }}</span>
                      <span class="text-xs">{{ currency }} {{ item.price }}</span>
                    </div>
                  </div>
                  <div
                    class="flex flex-col w-full pt-2 pb-3 space-y-1"
                    v-if="product.extras.length"
                  >
                    <div
                      class="flex aic jcb"
                      v-for="(item, i) in product.extras"
                      :key="i"
                    >
                      <span class="text-gray-600 text-xs"><b class="mr-2">{{ i + 1 }}.</b> {{ item.item }}</span>
                      <span class="text-xs">{{ currency }} {{ item.price }}</span>
                    </div>
                  </div>
                  <div class="flex aic jcb pt-3">
                    <span class="text-gray-600 text-sm b">Total </span>
                    <span class="text-sm b">{{ currency }} {{ product.totalPrice }}</span>
                  </div>
                </div>
              </div>

              <div class="flex aic jcb py-3 px-6 md:px-0">
                <div class="flex flex-col md:flex-row md:space-x-8 space-y-2 md:space-y-0">
                  <div class="flex aic space-x-4">
                    <i class="fa fa-bicycle fs-20 text-gray-400"></i><span class="fs-13">{{ $t("Delivery Cost") }} : {{ currency
                      }}{{ shippigPriceOrder }}</span>
                  </div>
                  <!-- <div class="flex aic space-x-4">
                    <i class="fa fa-minus-square-o fs-18 text-gray-400"></i><span class="fs-13">{{ $t("Discount") }} : {{ currency }}
                      {{ order.voucher_value }}</span>
                  </div> -->
                </div>
                <span class="fs-14">{{ $t("Total") }} : {{ currency }}
                  {{ order.total_with_discount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full aic jcc">
      <router-link
        to="/dashboard"
        class="flex aic btn-primary px-6 py-4 uppercase grow fs-14 tracking-widest"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 mr-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 19l-7-7m0 0l7-7m-7 7h18"
          />
        </svg>
        {{ $t("Continue shopping") }}
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  date() {
    return {};
  },
  methods: {
    formatDate(dateIn) {
      if (!dateIn) return;
      // let deliveryTime = dateIn.split("T");
      // deliveryTime[1] = deliveryTime[1].slice(0, 8);
      // deliveryTime = deliveryTime.join(" ");
      var date = new Date(dateIn);
      var monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      var timeIn = `${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
      // if (!time) return day + " " + monthNames[monthIndex] + " " + year;
      // else
      return day + " " + monthNames[monthIndex] + " " + year + "  " + timeIn;
    },
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
    paymentId() {
      return this.$route.query.paymentId || null;
    },
    paymentStatus() {
      return this.$store.state.orderiomApiPackage.order.paymentStatus;
    },
    productOrder() {
      return this.$store.state.orderiomApiPackage.order.productOrder;
    },
    userOrder() {
      return this.$store.state.orderiomApiPackage.order.userOrder;
    },
    order() {
      return this.$store.state.orderiomApiPackage.order.order;
    },
    table() {
      return this.$store.state.orderiomApiPackage.order.tableOrder;
    },
    totalPriceOrder() {
      return this.$store.state.orderiomApiPackage.order.totalPriceOrder;
    },
    shippigPriceOrder() {
      return this.$store.state.orderiomApiPackage.order.shippigPriceOrder;
    },
    restaurantId() {
      return localStorage.getItem("restaurantId");
    },
    basketId() {
      if (!localStorage.getItem("privateToken")) {
        const basket = JSON.parse(localStorage.getItem("basket"));
        const basketId = basket.find(
          (basket) => basket.restaurantId == this.restaurantId
        ).basketId;
        return basketId;
      } else return null;
    },
  },
  mounted() {
    this.$store.dispatch("order/getOrder", this.orderId);
    if (this.paymentId) {
      this.$store.dispatch("order/updatePaymentStatus", {
        paymentId: this.paymentId,
      });
    }
  },
};
</script>
